import { Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Address from "../../components/address";
import ContactForm from "../../components/contact-form";
import Hero from "../../utils/hero";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";

const ContattiPage = () => {
  return (
    <Page
      lang="en"
      title="Contacts"
      description="Mettifogo Srl is a company specialized in the production and commercialization of personalized items for the catering and Horeca world."
      type="ContactPage"
      image="/contacts.jpg"
    >
      <Hero
        title="Contacts"
        description="Do you have any doubts? We are at your complete disposal to answer any questions."
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Mint fragrance"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={5}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11203.426658234346!2d11.1258547!3d45.4122299!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf315f7349d4d0314!2sMettifogo!5e0!3m2!1sen!2sit!4v1641317272091!5m2!1sen!2sit"
              width="100%"
              height="300"
              style={{ border: 0 }}
              loading="lazy"
            ></iframe>
          </Grid>
          <Grid item xs={12} md={6}>
            <Address language="en" />
          </Grid>
        </Grid>
      </Section>

      <ContactForm language="en" />
    </Page>
  );
};

export default ContattiPage;
